import { GetStaticPaths } from 'next'
import { paths, slugs } from '@/constants'
import { Durations } from '@/constants/durations'
import { ProjectMetaProvider } from '@/contexts/ProjectMetaContext'
import { TheatricalRegionProvider } from '@/contexts/TheatricalRegionContext/TheatricalRegionContext'
import { Seo } from '@/layout/Seo'
import { TVSeriesJsonLd } from '@/layout/Seo/JsonLd'
import { NextPageWithLayout } from '@/layouts'
import { getTheatricalReleaseBySlug } from '@/services/CmsService'
import { CatalogSeries, fetchTitle, mapJsonLdProps, mapSeoProps } from '@/services/ContentCatalog'
import { getLightFranchiseBySlug, LightFranchise } from '@/services/FranchiseService'
import { getProjectLivestreams, PlayableLivestream } from '@/services/LivestreamService'
import { isStreaming } from '@/services/PhaseManagerService'
import { getContentfulProjectTheme } from '@/services/ProjectThemeService'
import { getProjectAbout, ProjectMeta } from '@/services/ProjectsService'
import {
  formatFilmName,
  getTheatricalPreReleasePageData,
  GetTheatricalPreReleasePageDataResult,
  isProjectThatsBothSeriesAndMovie,
  isTheChosen,
  useSeriesTitle,
} from '@/services/TheatricalPreReleaseService'
import { getWhereToWatch, WhereToWatch } from '@/services/WhereToWatchService'
import { ProjectTheme, TheatricalRegion } from '@/types/codegen-contentful'
import { isDevelopmentEnvironment } from '@/utils/environment-utils'
import { getStaticPropsErrorHandler } from '@/utils/nextUtils/nextErrorHandlers'
import { omitUndefineds } from '@/utils/object'
import { isSeries, isSpecial } from '@/utils/project-utils'
import { redact } from '@/utils/sensitive-data'
import { shouldHideFromSearchEngines } from '@/utils/seo/searchEngineHelper'
import { loadTranslations } from '@/utils/translate/translate-server'
import { WatchTheatricalView } from '@/views/WatchTheatricalView'
import { WatchTheatricalContextProvider } from '@/views/WatchTheatricalView/WatchTheatricalContext'

export const ShowsPage: NextPageWithLayout<
  ProjectMeta & {
    catalogTitle?: CatalogSeries
    isStreaming: boolean
    livestreams: PlayableLivestream[] | null
    locale: string
    pageData: GetTheatricalPreReleasePageDataResult
    supportedTheatricalRegions: TheatricalRegion[]
    theatricalRegion: TheatricalRegion[]
    whereToWatch: WhereToWatch
    contentfulProjectTheme: ProjectTheme
    franchise?: LightFranchise
    projectMeta: ProjectMeta
  }
> = (props) => {
  const { filmDetails } = props.pageData
  const { isStreaming, locale, franchise, projectMeta } = props
  const shouldHide = shouldHideFromSearchEngines(props)

  const path = `${paths.shows.index}/${props.slug}`
  const catalogTitle = props.catalogTitle
  const title = useSeriesTitle({
    filmName: catalogTitle ? formatFilmName(catalogTitle) : projectMeta.name,
    isStreaming,
    releaseYear: filmDetails?.releaseYear,
  })

  return (
    <>
      <Seo
        {...mapSeoProps({ path, title, catalogTitle, locale, projectMeta })}
        noindex={shouldHide}
        nofollow={shouldHide}
        path={path}
      />
      {catalogTitle && <TVSeriesJsonLd {...mapJsonLdProps({ path, catalogTitle, locale })} />}
      <ProjectMetaProvider project={props}>
        <TheatricalRegionProvider
          supportedTheatricalRegions={props.supportedTheatricalRegions}
          regionData={props.theatricalRegion}
        >
          <WatchTheatricalContextProvider pageData={props.pageData} franchise={franchise}>
            <WatchTheatricalView
              catalogTitle={catalogTitle}
              livestreams={props.livestreams}
              whereToWatch={props.whereToWatch}
              contentfulProjectTheme={props.contentfulProjectTheme}
            />
          </WatchTheatricalContextProvider>
        </TheatricalRegionProvider>
      </ProjectMetaProvider>
    </>
  )
}

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps = getStaticPropsErrorHandler(
  { filePath: 'pages/[locale]/shows/[projectSlug]/index.tsx' },
  async ({ federationClient, locale, next404, params, preview }) => {
    const project = (params?.projectSlug as string).toLowerCase()

    let projectMeta = await getProjectAbout({ slug: project }, federationClient, { locale, preview })
    if (!projectMeta) {
      return next404()
    }

    const livestreams = await getProjectLivestreams(projectMeta, project, federationClient)
    const shouldIncludePreview = isDevelopmentEnvironment()
    const pageData = await getTheatricalPreReleasePageData({ slug: project }, federationClient, { locale, preview })

    const whereToWatch = await getWhereToWatch(project, preview)
    const contentfulProjectTheme = await getContentfulProjectTheme(project, preview)

    const catalogTitle = await fetchTitle<CatalogSeries>(locale, pageData.contentCatalogId)

    const theatricalRelease = await getTheatricalReleaseBySlug({
      theatricalSlug: project,
      opts: { preview: shouldIncludePreview, locale },
    })

    let franchise
    if (projectMeta.franchiseDetails?.slug) {
      franchise = await getLightFranchiseBySlug(projectMeta.franchiseDetails.slug)
    }

    const regionData = theatricalRelease?.regionCollection || null
    const supportedTheatricalRegions = theatricalRelease?.supportedTheatricalRegions || null

    // Projects may have a hub page and not be in Hydra so these values are undefined
    if (projectMeta.slug === undefined) {
      projectMeta = { ...projectMeta, slug: project }
    }

    if (projectMeta.public === undefined || (!projectMeta.public && pageData?.filmDetails?.sys?.id && catalogTitle)) {
      projectMeta = { ...projectMeta, public: true }
    }

    const redirect = getRedirect(project, projectMeta)
    if (redirect) return redirect

    if (
      (!isSeries(projectMeta) && !isSpecial(projectMeta) && !isProjectThatsBothSeriesAndMovie(projectMeta)) ||
      isTheChosen(project)
    ) {
      return next404(Durations.FIFTEEN_MINUTES_IN_SECONDS)
    }

    return {
      props: {
        catalogTitle: omitUndefineds(catalogTitle),
        isStreaming: isStreaming(projectMeta.primaryFlowPhases ?? []),
        livestreams,
        locale,
        pageData,
        supportedTheatricalRegions,
        theatricalRegion: regionData,
        whereToWatch,
        franchise: omitUndefineds(franchise),
        contentfulProjectTheme,
        projectMeta,
        ...redact(projectMeta, ['primaryFlowPhases']),
        ...(await loadTranslations(locale, [
          'common',
          'home',
          'watch',
          'app-promo',
          'ads',
          'account',
          'theatrical-presales',
          'pif',
          'guild',
        ])),
      },
      revalidate: catalogTitle ? Durations.FIFTEEN_MINUTES_IN_SECONDS : Durations.ONE_MINUTE_IN_SECONDS,
    }
  },
)

export default ShowsPage

const getRedirect = (project: string, projectMeta: ProjectMeta) => {
  if (!projectMeta.hasHubPage) {
    return {
      redirect: {
        destination: `${paths.watch.index}/${project}`,
        permanent: false,
      },
    }
  }

  if (project === slugs.truthAndConviction) {
    return {
      redirect: {
        destination: paths.shows.truthAndTreasonSeries,
        permanent: false,
      },
    }
  }

  if (project === slugs.truthAndTreason) {
    return {
      redirect: {
        destination: paths.movies.truthAndTreason,
        permanent: false,
      },
    }
  }

  return null
}
